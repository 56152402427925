<template>
  <div>

    <div class="site-content pt-4">
      <div class="container">
        <h5 class="mb-4">OUR SERVICES | CONSULTANCY</h5>
        <div class="row">
          <div class="col-md-6">
            <h4>Consultancy | Carbon Footprint Audits</h4>
            <p>
              With increasing interest in the impact of our actions on the
              climate, many organisations are looking for ways to reduce their
              carbon footprint and become “carbon neutral”. This may be driven
              by Corporate Social Responsibility (CSR) requirements, or simply
              the desire to take responsibility for how our activities impact
              the climate, and demonstrate this to our members, partners or
              customers.
            </p>
            <p>
              An organisation can claim to be carbon neutral for an activity
              once it has measured the carbon footprint of that activity,
              reduced emissions where possible and offset the rest.
            </p>
            <p>
              expertise in all areas of carbon auditing and is well placed to
              help your NGO or business calculate your organisational carbon
              footprint and recommend ways to reduce it. Each organisation is
              unique, so our advice is tailor-made to suit your organisation and
              the outcomes you wish to achieve.
            </p>
          </div>
          <div class="col-md-6 text-center desktop-only">
            <img src="/theme/services1.png" alt="service" class="h-100">
          </div>
        </div>
        <hr class="mobile-only">
        <div class="row mt-5">
          <div class="col-md-6 desktop-only">
            <img src="/theme/services2.png" alt="service" class="h-100">
          </div>
          <div class="col-md-6">
            <h4>Consultancy | Carbon Footprint Audits</h4>
            <p>
              With increasing interest in the impact of our actions on the
              climate, many organisations are looking for ways to reduce their
              carbon footprint and become “carbon neutral”. This may be driven
              by Corporate Social Responsibility (CSR) requirements, or simply
              the desire to take responsibility for how our activities impact
              the climate, and demonstrate this to our members, partners or
              customers.
            </p>
            <p>
              An organisation can claim to be carbon neutral for an activity
              once it has measured the carbon footprint of that activity,
              reduced emissions where possible and offset the rest.
            </p>
            <p>
              expertise in all areas of carbon auditing and is well placed to
              help your NGO or business calculate your organisational carbon
              footprint and recommend ways to reduce it. Each organisation is
              unique, so our advice is tailor-made to suit your organisation and
              the outcomes you wish to achieve.
            </p>
          </div>
        </div>
        <div class="row mt-5">
          <h5>Our Clients</h5>
          <hr>
          <div class="row">
            <div class="col-6 col-md-3 mb-4" v-for="i in 8" :key="`brand-${i}`">
              <img src="/theme/brand.png" alt="brand" class="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  
};
</script>
