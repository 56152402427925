<template>
  <footer class="bg-primary">
    <div class="container">
      <div class="row">
        <div class="col-md-3 mb-4 mb-md-0">
          <div class="footer-logo">
            <img src="/logo2-light.svg" alt="logo" class="footer-logo">
            <p class="mb-1 mt-3">Kirichwa Road, Kilimani, Nairobi - Kenya</p>
            <p class="mb-1">Phone: +254 720 310 021</p>
            <p class="mb-4">Email: enquiries@carbonexchange.africa</p>
            <p class="socials mb-0">
              <a href="#"><i class="fab fa-facebook"></i></a>
              <a href="#"><i class="fab fa-twitter"></i></a>
              <a href="#"><i class="fab fa-instagram"></i></a>
            </p>
          </div>
        </div>
        <div class="col-6 col-md-3 ps-md-5">
          <h4 class="footer-title">Resources</h4>
          <ul class="footer-menu">
            <li>
              <router-link to="">Whitepaper</router-link>
            </li>
            <li>
              <router-link to="">Token Sales</router-link>
            </li>
            <li>
              <router-link to="">Privacy Policy</router-link>
            </li>
            <li>
              <router-link to="">Terms & Conditions</router-link>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-3 ps-md-5">
          <h4 class="footer-title">Links</h4>
          <ul class="footer-menu">
            <li>
              <router-link to="">Tokens</router-link>
            </li>
            <li>
              <router-link to="">Roadmap</router-link>
            </li>
            <li>
              <router-link to="">FAQs</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-3 mt-4 mt-md-0">
          <h4 class="footer-title">Latest News</h4>
          <div class="latest-news">
            <div class="news" v-for="i in 2" :key="`news-${i}`">
              <div class="badge">Carbon Zero</div>
              <router-link to="">Lorem ipsum dolor sit amet.</router-link>
              <div class="date"><i class="fa fa-calendar me-2"></i>04 Mar 2024</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  
}
</script>